import React, { useState, useEffect } from 'react';
import LocationInputForm from '../LocationInput/LocationInputForm';
import axios from 'axios';
import './EndorsementForm.css'; // Import the CSS file

const EndorsementForm = ({ ID }) => {
  const debounceTimeout = 300; // 300ms delay for debounce
  let typingTimeout = null;
  const [comments, setComments] = useState('');
  const [endorserType, setEndorserType] = useState('organization');
  const [organizationDetails, setOrganizationDetails] = useState({
    name: '',
    image: '',
    email: '',
    mobile: '',
    location: '',
    website: '',
    tagline:'',
  });
  const [individualDetails, setIndividualDetails] = useState({
    name: '',
    email: '',
    mobile: '',
    title: '',
    linkedin: '',
    image: null, // Add image field
  });
  const [suggestedOrganizations, setSuggestedOrganizations] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [disableInput, setDisableInput] = useState(false);
  const [suggestedIndividuals, setSuggestedIndividuals] = useState([]);


  const handleEndorserTypeChange = (e) => {
    setEndorserType(e.target.value);
  };
  const handleSuggestedIndividualClick = (individual) => {
    setIndividualDetails({
      name: individual.name,
      email: individual.email,
      mobile: individual.mobile,
      title: individual.title,
      linkedin: individual.linkedin,
      image: individual.image || null,
    });
    setShowSuggestions(false);
    setDisableInput(true); // Disable all fields after selecting a suggestion

  };
  const handleOrganizationDetailChange = (e) => {
    const { name, value } = e.target;
    setOrganizationDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    if (name === 'name') {
      setShowSuggestions(value !== '');
      setDisableInput(false); // Enable input when organization name changes
    }
  };
  const handleSuggestedOrganizationClick = (organizationId) => {
    const orgData = suggestedOrganizations.find(org => org._id === organizationId);
    if (orgData) {
      if (orgData.verified) {
        setOrganizationDetails({
          name: orgData.name,
          email: orgData.email,
          mobile: orgData.mobile,
          location: orgData.location,
          website: orgData.website,
          tagline: orgData.tagline,
          image: '', // Keep image empty as it's not fetched from the server
        });
        setShowSuggestions(false);
        setDisableInput(true);
      } else {
        alert('This organization is not verified.');
      }
    }
  };
  const handleIndividualDetailChange = (e) => {
    const { name, value } = e.target;
    setIndividualDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    // If the user is typing in the 'name' field, perform a debounced search
    if (name === 'name' && value) {
      clearTimeout(typingTimeout);
      typingTimeout = setTimeout(() => {
        searchIndividuals(value);
      }, debounceTimeout);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setIndividualDetails((prevDetails) => ({
      ...prevDetails,
      image: file, // Store the image file in the state
    }));
  };

  const handleCommentsChange = (e) => {
    const { value } = e.target;
    setComments(value);
  };
  const searchIndividuals = (query) => {
    axios.get(`${process.env.REACT_APP_URLVAR}/api/individuals?suggest=${query}`)
      .then((response) => {
        setSuggestedIndividuals(response.data); // Assuming setSuggestedIndividuals is a state setter for individual suggestions
        setShowSuggestions(true);
        
      })
      .catch((error) => {
        console.error('There was an error fetching the individuals!', error);
      });
  };
  

  const handleLocationSelect = (location) => {
    setOrganizationDetails((prevDetails) => ({
      ...prevDetails,
      location: {
        location: `${location.city}, ${location.state}, ${location.country}`,
        lat: `${location.latitude}`,
        lng: `${location.longitude}`,
      },
    }));
  };

  useEffect(() => {
    if (organizationDetails.name) {
      axios.get(`${process.env.REACT_APP_URLVAR}/api/organizations?suggest=${organizationDetails.name}`)
        .then((response) => {
          setSuggestedOrganizations(response.data);
          setShowSuggestions(true);
        })
        .catch((error) => {
          console.error('There was an error fetching the organizations!', error);
        });
    }
  }, [organizationDetails.name]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (endorserType === 'organization') {
      const formData = new FormData();
      formData.append('name', organizationDetails.name);
      formData.append('email', organizationDetails.email);
      formData.append('mobile', organizationDetails.mobile);
      formData.append('website', organizationDetails.website);
      formData.append('tagline', organizationDetails.tagline);
      formData.append('location', JSON.stringify(organizationDetails.location));
      formData.append('comments', comments);
      formData.append('leaderId', ID);
      formData.append('verified', false);

      if (organizationDetails.image) {
        formData.append('image', organizationDetails.image);
      }

      axios.post(`${process.env.REACT_APP_URLVAR}/api/organizations/endorse`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => {
          alert('Organization endorsement submitted successfully:');
        })
        .catch((error) => {
          if (error.response && error.response.status === 409) {
            alert(error.response.data.message);
          } else {
            console.error('There was an error submitting the organization endorsement!', error);
          }
        });
    } else if (endorserType === 'individual') {
      const formData = new FormData();
      formData.append('name', individualDetails.name);
      formData.append('email', individualDetails.email);
      formData.append('mobile', individualDetails.mobile);
      formData.append('title', individualDetails.title);
      formData.append('linkedin', individualDetails.linkedin);
      formData.append('comments', comments);
      formData.append('leaderId', ID);

      if (individualDetails.image) {
        formData.append('image', individualDetails.image); // Append image to FormData
      }

      axios.post(`${process.env.REACT_APP_URLVAR}/api/individual/endorse`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => {
          alert('Individual endorsement submitted successfully');
        })
        .catch((error) => {
          console.error('There was an error submitting the individual endorsement!', error);
        });
    }
  };
  return (
    <div className='endorsementform px-3'>
      <div className="form-container container shadow-lg">
        <div className="form-header">
          <button className={`form-tab ${endorserType === 'organization' ? 'active' : ''}`} onClick={() => setEndorserType('organization')}>Organization</button>
          <button className={`form-tab ${endorserType === 'individual' ? 'active' : ''}`} onClick={() => setEndorserType('individual')}>Individual</button>
        </div>
        <form className='form-body' onSubmit={handleSubmit}>
          {endorserType === 'organization' && (
            <div className="form-section">
              <div className="form-group">
                <label htmlFor="organizationName">Organization Name</label>
                <input
                  type="text"
                  id="organizationName"
                  className="form-control"
                  name="name"
                  value={organizationDetails.name}
                  onChange={handleOrganizationDetailChange}
                  required
                />
                {showSuggestions && Array.isArray(suggestedOrganizations) && suggestedOrganizations.length > 0 && (
                  <div className='suggestions'>
                    { suggestedOrganizations.map((org) => (
                      <div key={org._id} onClick={() => handleSuggestedOrganizationClick(org._id)} className="suggestion-item">
                        {org.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="organizationEmail">Organization Email</label>
                <input
                  type="email"
                  id="organizationEmail"
                  className="form-control"
                  name="email"
                  value={organizationDetails.email}
                  onChange={handleOrganizationDetailChange}
                  
                  disabled={disableInput}
                />
              </div>
              <div className="form-group">
                <label htmlFor="organizationMobile">Organization Mobile</label>
                <input
                  type="text"
                  id="organizationMobile"
                  className="form-control"
                  name="mobile"
                  value={organizationDetails.mobile}
                  onChange={handleOrganizationDetailChange}
                  
                  disabled={disableInput}
                />
              </div>
              <div className="form-group">
                <label htmlFor="location">Location</label>
                <LocationInputForm onLocationSelect={handleLocationSelect} enteredvalue={organizationDetails.location} disabled={disableInput} />
              </div>
              <div className="form-group">
                <label htmlFor="organizationImage">Organization Logo</label>
                <input
                  type="file"
                  id="organizationImage"
                  className="form-control"
                  name="image"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    setOrganizationDetails((prevDetails) => ({
                      ...prevDetails,
                      image: file,
                    }));
                  }}
                  required
                  disabled={disableInput}
                />
              </div>
              <div className="form-group">
                <label htmlFor="organizationWebsite">Organization website</label>
                <input
                  type="url"
                  id="organizationWebsite"
                  className="form-control"
                  name="website"
                  value={organizationDetails.website}
                  onChange={handleOrganizationDetailChange}
                  required
                  disabled={disableInput}
                />
              </div>
              <div className="form-group1">
                <label htmlFor="organizationTagline">Organization Tagline</label>
                <input
                  type="text"
                  id="organizationTagline"
                  className="form-control"
                  name="tagline"
                  value={organizationDetails.tagline}
                  onChange={handleOrganizationDetailChange}
                  required
                  disabled={disableInput}
                />
              </div>
            </div>
          )}

          {endorserType === 'individual' && (
            <div className="form-section">
            <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  className="form-control"
                  name="name"
                  value={individualDetails.name}
                  onChange={handleIndividualDetailChange}
                  required
                />
                {showSuggestions && Array.isArray(suggestedIndividuals) && suggestedIndividuals.length > 0 && (
                  <div className='suggestions shadow col-md-4'>
                    {suggestedIndividuals.map((individual, index) => (
                      <div key={index} onClick={() => handleSuggestedIndividualClick(individual)} className="suggestion-item">
                        {individual.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  className="form-control"
                  name="email"
                  value={individualDetails.email}
                  onChange={handleIndividualDetailChange}
                  disabled={disableInput}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="mobile">Mobile No</label>
                <input
                  type="text"
                  id="mobile"
                  className="form-control"
                  name="mobile"
                  value={individualDetails.mobile}
                  onChange={handleIndividualDetailChange}
                  disabled={disableInput}
                />
              </div>
              <div className="form-group">
                <label htmlFor="linkedin">LinkedIn URL</label>
                <input
                  type="url"
                  id="linkedin"
                  className="form-control"
                  name="linkedin"
                  value={individualDetails.linkedin}
                  onChange={handleIndividualDetailChange}
                  disabled={disableInput}
                />
              </div>
              <div className="form-group">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  id="title"
                  className="form-control"
                  name="title"
                  value={individualDetails.title}
                  onChange={handleIndividualDetailChange}
                  disabled={disableInput}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="image">Profile Photo</label>
                <input
                  type="file"
                  id="image"
                  className="form-control"
                  name="image"
                  disabled={disableInput}
                  onChange={handleImageChange} // Handle image file change
                />
              </div>
            </div>
          )}

          <div className="form-group1 text-start mt-3">
            <label htmlFor="comments">Comments - Why do you support?</label>
            <textarea
              id="comments"
              className="form-control"
              name="comments"
              value={comments}
              onChange={handleCommentsChange}
            />
          </div>
          <button type="submit" className="btn btn-custom-primary mx-auto px-5 mt-3">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default EndorsementForm;
