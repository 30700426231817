import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import LocationInputForm from "../LocationInput/LocationInputForm";

const OrganizationEditForm = () => {
  const { id } = useParams();

  const [organization, setOrganization] = useState({
    name: "",
    email: "",
    mobile: "",
    website: "",
    tagline: "",
    location: {
    },
    image: "",
    leaderId: "",
    verified: false,
  });
  const [imageFile, setImageFile] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URLVAR}/api/general/organizations/${id}`,
          {
            headers: {
              "x-auth-token": localStorage.getItem("Token"),
            },
          }
        );
       
         setOrganization(response.data);
         
        
      } catch (error) {
        console.error("Error fetching organization:", error);
      }
    };
    fetchOrganization();
    
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith("location.")) {
      const field = name.split(".")[1];
      setOrganization((prev) => ({
        ...prev,
        location: { ...prev.location, [field]: value },
      }));
    } else {
      setOrganization((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleFileChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleLocationSelect = (location) => {
    console.log()
    setOrganization((prevDetails) => ({
      ...prevDetails,
      location: {
        location: `${location.city}, ${location.state}, ${location.country}`,
        lat: `${location.latitude}`,
        lng: `${location.longitude}`,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", organization.name);
    formData.append("email", organization.email);
    formData.append("mobile", organization.mobile);
    formData.append("website", organization.website);
    formData.append("tagline", organization.tagline);
    formData.append("location", JSON.stringify(organization.location));
    formData.append("leaderId", organization.leaderId);
    formData.append("verified", organization.verified);

    if (imageFile) {
      formData.append("image", imageFile);
    } else {
      formData.append("image", organization.image);
    }

    console.log(formData.get("name"));
    try {
      await axios.put(
        `${process.env.REACT_APP_URLVAR}/api/general/organizations/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-auth-token": localStorage.getItem("Token"),
          },
        }
      );
      alert("Edited Successfully");
    } catch (error) {
      console.error("Error saving organization:", error);
      setErrors({ submit: "Failed to save organization" });
    }
  };

  return (
    <div className="container">
      <h2>Edit Organization</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Name
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            value={organization.name || ""}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={organization.email || ""}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="mobile" className="form-label">
            Mobile
          </label>
          <input
            type="text"
            className="form-control"
            id="mobile"
            name="mobile"
            value={organization.mobile || ""}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="website" className="form-label">
            Website
          </label>
          <input
            type="text"
            className="form-control"
            id="website"
            name="website"
            value={organization.website || ""}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="tagline" className="form-label">
            Tagline
          </label>
          <input
            type="text"
            className="form-control"
            id="tagline"
            name="tagline"
            value={organization.tagline || ""}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="location">Location</label>
          <LocationInputForm
            onLocationSelect={handleLocationSelect}
            enteredvalue={organization.location}
          />
          
        </div>
        <div className="mb-3">
          <label htmlFor="image" className="form-label">
            Image
          </label>
          <input
            type="file"
            className="form-control"
            id="image"
            name="image"
            onChange={handleFileChange}
          />
          {organization.image && (
            <div className="mt-2">
              <img
                src={organization.image}
                alt="Organization"
                style={{ width: "100px" }}
              />
            </div>
          )}
        </div>
        
        <div className="mb-3 form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="verified"
            name="verified"
            checked={organization.verified}
            onChange={(e) =>
              setOrganization((prev) => ({
                ...prev,
                verified: e.target.checked,
              }))
            }
          />
          <label htmlFor="verified" className="form-check-label">
            Verified
          </label>
        </div>
        {errors.submit && (
          <div className="alert alert-danger">{errors.submit}</div>
        )}
        <button type="submit" className="btn btn-primary">
          Save
        </button>
      </form>
    </div>
  );
};

export default OrganizationEditForm;
