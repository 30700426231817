import React, { useEffect, useState } from 'react';

const LocationInputForm = ({ onLocationSelect , enteredvalue,  disabled}) => {
  const [citySuggestions, setCitySuggestions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (enteredvalue) {
      setInputValue(enteredvalue.location); // Assuming enteredvalue is an object with a location property
    }
  }, [enteredvalue]);
  const fetchCitySuggestions = async (query) => {
    const apiKey = process.env.REACT_APP_GEOCODE_API_KEY;
    const apiUrl = `https://api.opencagedata.com/geocode/v1/json?q=${query}&key=${apiKey}&limit=5`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      if (data.results) {
        const suggestions = data.results.map((result) => ({
          name: `${result.components.city || result.components.town || result.components.village || ''}, ${result.components.state || ''}, ${result.components.country || ''}`,
          lat: result.geometry.lat,
          lng: result.geometry.lng,
          city: result.components.city || result.components.town || result.components.village || '',
          state: result.components.state || '',
          country: result.components.country || '',
        }));
        setCitySuggestions(suggestions);
      } else {
        setCitySuggestions([]);
      }
    } catch (error) {
      console.error('Error fetching city suggestions:', error);
    }
  };

  const handleInputChange = (event) => {
    const searchText = event.target.value;
    setInputValue(searchText);
    if (searchText.length >= 3) {
      fetchCitySuggestions(searchText);
    } else {
      setCitySuggestions([]);
    }
  };

  const handleSelectLocation = (selected) => {
    setInputValue(selected.name);
    setCitySuggestions([]);
    onLocationSelect({
      latitude: selected.lat,
      longitude: selected.lng,
      city: selected.city,
      state: selected.state,
      country: selected.country,
    });
  };

  return (
    <div className="form-group">
      {/* <label htmlFor="cityInput">City</label> */}
      <input
        type="text"
        className="form-control"
        id="cityInput"
        value={inputValue}
        placeholder="Enter city"
        onChange={handleInputChange}
        disabled={disabled}
      />
      {citySuggestions.length > 0 && (
        <ul className="list-group position-absolute mt-2">
          {citySuggestions.map((suggestion, index) => (
            <li
              key={index}
              className="list-group-item"
              onClick={() => handleSelectLocation(suggestion)}
              style={{ cursor: 'pointer' }}
            >
              {suggestion.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LocationInputForm;
