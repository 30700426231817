import React, { useEffect, useState } from 'react';
import axios from 'axios';

const AdminVerifyOrganizations = () => {
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    fetchUnverifiedOrganizations();
  }, []);

  const fetchUnverifiedOrganizations = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URLVAR}/api/general/organizations/unverified`,
        { headers: {
           
           'x-auth-token': localStorage.getItem("Token"),
         }});
      console.log(response.data)
      setOrganizations(response.data);
    } catch (error) {
      console.error('Error fetching unverified organizations:', error);
    }
  };

  const handleVerify = async (organizationId) => {
    try {
      await axios.put(`${process.env.REACT_APP_URLVAR}/api/general/organizations/verify/${organizationId}`,{},
        { headers: {
           'x-auth-token': localStorage.getItem("Token"),
         }});
      setOrganizations(organizations.filter(org => org._id !== organizationId));
    } catch (error) {
      console.error('Error verifying organization:', error);
    }
  };

  return (
    <div className="container mt-5">
      <h2>Verify Organizations</h2>
      {organizations.length === 0 ? (
        <p>No organizations to verify.</p>
      ) : (
        <div className='table-responsive'>

        <table className="table ">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>website</th>
              <th>Location</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(organizations) && organizations.map(org => (
              <tr key={org._id}>
                <td>{org.name}</td>
                <td>{org.email}</td>
                <td>{org.mobile}</td>
                <td><a href={org.website}>Website</a></td>
                <td>{org.location.location}</td>
                <td>
                  <button className="btn btn-success" onClick={() => handleVerify(org._id)}>Verify</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      )}
    </div>
  );
};

export default AdminVerifyOrganizations;
