import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
const EventRSVPList = () => {
    const { eventId } = useParams();
  const [rsvps, setRsvps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchRSVPs = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_URLVAR}/api/event/rsvps/${eventId}/`, {
          headers: { 'x-auth-token': localStorage.getItem("Token") },
        });
        setRsvps(response.data.rsvps);
        setLoading(false);
      } catch (err) {
        setError('Error fetching RSVPs.');
        setLoading(false);
      }
    };

    if (eventId) {
      fetchRSVPs();
    }
  }, [eventId]);

  if (loading) {
    return <p>Loading RSVPs...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="container mt-4">
      <h3>RSVPs for Event</h3>
      {rsvps.length === 0 ? (
        <p>No RSVPs found for this event.</p>
      ) : (
        <table className="table table-striped mt-3">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Guests</th>
            </tr>
          </thead>
          <tbody>
            {rsvps&&rsvps.map((rsvp) => (
              <tr key={rsvp._id}>
                <td>{rsvp.name}</td>
                <td>{rsvp.email}</td>
                <td>{rsvp.phoneNumber}</td>
                <td>{rsvp.numberOfGuests}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default EventRSVPList;
