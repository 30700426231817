import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const OrganizationList = () => {
  const [organizations, setOrganizations] = useState([]);
//   const history = useHistory();

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const link =`${process.env.REACT_APP_URLVAR}/api/general/organizations`;
        const response = await axios.get(link,
            { headers: {
               
               'x-auth-token': localStorage.getItem("Token"),
             }});
        setOrganizations(response.data);
        console.log(link)
      } catch (error) {
        console.error('Error fetching organizations:', error);
      }
    };
    fetchOrganizations();
  }, []);

  const deleteOrganization = async (id) => {
    try {
     await axios.delete(`${process.env.REACT_APP_URLVAR}/api/general/organizations/${id}`,
            { headers: {
               
               'x-auth-token': localStorage.getItem("Token"),
             }});
      setOrganizations(organizations.filter(org => org._id !== id));
alert("Deleted Successfully")
    } catch (error) {
      console.error('Error deleting organization:', error);
    }
  };

  return (
    <div className="mx-5">
      <h2>Organizations</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th style={{ width: '150px' }}>Email</th>
            <th>Mobile</th>
            <th>Website</th>
            <th>Tagline</th>
            <th>Location</th>
            <th>Image</th>
            {/* <th>Leader ID</th> */}
            <th>Verified</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(organizations) && organizations.map((organization) => (
            <tr key={organization._id}>
              <td>{organization.name}</td>
              <td >{organization.email}</td>
              <td>{organization.mobile}</td>
              <td>{organization.website}</td>
              <td className='scrollable-tagline'><textarea  rows={3} value={organization.tagline} disabled/></td>
              <td>{organization.location.location}</td>
              <td><img src={organization.image} alt={organization.name} width="50" /></td>
              {/* <td>{organization.leaderId}</td> */}
              <td>{organization.verified ? 'Yes' : 'No'}</td>
              <td>
                <Link to={`/organizations/edit/${organization._id}`} className="btn btn-warning btn-sm me-2">Edit</Link>
                <button onClick={() => deleteOrganization(organization._id)} className="btn btn-danger btn-sm">Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrganizationList;
