// src/components/LeaderCard.js
import React from "react";
// import Image from "../../img/sabina.png";
import { Link } from "react-router-dom";
const ProfileCardSmall = ({ leader, slug }) => (
  
  <div className="card shadow small-card-height" >
    <img src={leader.image} alt={leader.name} className="card-img-top" loading="lazy"/>
    <div className="card-body text-center mt-4">
      <Link to={`/profile/${leader._id}`} className="h5 card-title">
        {leader.title}
      </Link>

      
      <p className="card-subtitle text-muted">{leader.name}</p>
      <p className="card-subtitle text-muted">{leader.location}</p>
      <p className="card-text ">{leader.tagline}</p>
      <div className="social-links position-absolute start-50 translate-middle-x bottom-0 mb-5">
        {leader.socials &&
          leader.socials.map((social, index) => (
            <a
              key={index}
              href={social.link}
              className={social.platform}
              aria-label={`${social.platform} link for ${leader.name}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className={`bi bi-${social.platform}`}></i>
            </a>
          ))}
      </div>
      <Link
        to={`/profile/${slug}`}
        className="btn my-2  btn-custom-primary know-more position-absolute "
      >
       View More
      </Link>
    </div>
  </div>
);

export default ProfileCardSmall;
