import React, { useState } from 'react';
import axios from 'axios';
import Papa from 'papaparse';

const EmailSender = () => {
  const [recipients, setRecipients] = useState('');
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');
  const [html, setHtml] = useState('');
  const [status, setStatus] = useState('');
  const [csvEmails, setCsvEmails] = useState([]);

  // Function to handle CSV file upload
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: function(results) {
          const emails = results.data.map(row => row[0]).filter(email => email); // Extracting emails from CSV
          setCsvEmails(emails);
        }
      });
    }
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Combine CSV emails and manually entered emails
    const recipientArray = [...csvEmails, ...recipients.split(',').map(email => email.trim())].filter(email => email);

    const emailData = {
      recipients: recipientArray,
      subject,
      text,
      html,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_URLVAR}/api/event/send-emails`, emailData);
      setStatus('Emails sent successfully!');
      console.log(response.data);
    } catch (error) {
      setStatus('Error sending emails.');
      console.error(error);
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Send Personalized Emails</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">Upload CSV File with Emails:</label>
          <input
            type="file"
            className="form-control"
            accept=".csv"
            onChange={handleFileUpload}
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Additional Recipients (comma separated):</label>
          <input
            type="text"
            className="form-control"
            value={recipients}
            onChange={(e) => setRecipients(e.target.value)}
            placeholder="Enter email addresses separated by commas"
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Subject:</label>
          <input
            type="text"
            className="form-control"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder="Email Subject"
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Text Body:</label>
          <textarea
            className="form-control"
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Enter plain text body"
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label">HTML Body:</label>
          <textarea
            className="form-control"
            value={html}
            onChange={(e) => setHtml(e.target.value)}
            placeholder="Enter HTML body"
          />
        </div>

        <button type="submit" className="btn btn-primary">Send Emails</button>
      </form>
      {status && <p className="mt-3">{status}</p>}
    </div>
  );
};

export default EmailSender;
