import React, { useState, useEffect } from 'react';

const BlogList = ({ numToShow }) => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    fetch('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@social21774')
      .then(response => response.json())
      .then(data => {
        console.log(data.items);
        setBlogs(data.items); // assuming data.items contains the blog posts
      })
      .catch(error => console.error('Error fetching RSS feed:', error));
  }, []);

  // Function to extract image src from description
  const extractImageSrc = (description) => {
    const imgTagMatch = description.match(/<img[^>]+src="([^">]+)"/);
    return imgTagMatch ? imgTagMatch[1] : null;
  };

  // Determine how many blogs to show
  const blogsToShow = numToShow ? blogs && blogs.slice(0, numToShow) : blogs;

  return (
    <div className="container mt-5">
      <h2 className="about-heading my-5 text-center">Blogs</h2>

      <div className="row w-100 mx-auto">
        {blogsToShow && blogsToShow.map((blog) => {
          const imgSrc = extractImageSrc(blog.description);
          return (
            <div className="col-md-4 d-flex " key={blog.guid}>
              <div className="card mb-4 d-flex flex-grow-1 flex-column">
                {imgSrc && (
                  <img
                    className="card-img-top"
                    src={imgSrc}
                    alt={blog.title}
                  />
                )}
                <div className="card-body my-3">
                  <h5 className="fw-bold">{blog.title}</h5>
                  <div className="mt-auto">
                    <a href={blog.link} className="btn btn-custom-primary " target="_blank" rel="noopener noreferrer">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

// Default props in case numToShow is not passed
BlogList.defaultProps = {
  numToShow: null, // Show all by default
};

export default BlogList;
