import React, { useState, useEffect } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./ProfileCard.css";
import { Link } from "react-router-dom";
import priorityimg from "../../img/righttick.png";

const ProfileCard = ({
  id,
  name,
  title,
  location,
  image,
  commitment,
  distance,
  priorities,
  slug,
}) => {
  const [showAll, setShowAll] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (image) {
      // Preload the dynamically fetched image
      const link = document.createElement("link");
      link.rel = "preload";
      link.href = image;
      link.as = "image";
      document.head.appendChild(link);

      // Cleanup: remove the link tag when the component unmounts or when the image changes
      return () => {
        document.head.removeChild(link);
      };
    }
  }, [image]);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  // Determine how many priorities to display
  // const maxPriorities = showAll ? priorities.length : isMobile ? 2 : 6;
  // const displayedPriorities = priorities.slice(0, maxPriorities);

  // Calculate how many empty spaces to add
  const minPriorities = isMobile ? 2 : 4;
  // const emptySpaces = Math.max(0, minPriorities - displayedPriorities.length);
  const wordstitle = title.split(" ");
  return (
    <div className="profile-card mt-5 pt-3">
      <div className="card bg-img">
        {/* <div className="overlay"></div> */}
        <div className="row g-0 content">
          <div className="col-md-8 px-5 mobile-padding">
            <div className="card-body card-body-custom pt-5 pb-0  d-flex flex-column justify-content-between">
              <div>
                <h2 className="card-title text-secondary priority-title">
                  <span className="about-heading fs-1">{`${wordstitle[0]} ${wordstitle[1]}`}</span>{" "}
                  <br />
                  {` ${wordstitle.slice(2).join(" ")}`}
                </h2>
                {commitment && (
                  <div
                    className={`text-secondary my-3 commitment ${
                      showAll ? "commitment-mobile" : ""
                    }`}
                  >
                    <h4 className="">{commitment}</h4>
                  </div>
                )}
                <div className="card-text pt-4 fs-4 priority-location">
                  {/* {name} <br /> */}
                  <strong>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.0"
                      width="28px"
                      height="28px"
                      viewBox="0 0 472.000000 479.000000"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <metadata>
                        Created by potrace 1.10, written by Peter Selinger
                        2001-2011
                      </metadata>
                      <g
                        transform="translate(0.000000,479.000000) scale(0.100000,-0.100000)"
                        fill="#6c757d"
                        stroke="#6c757d"
                        stroke-width="200"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M1808 4375 c-190 -39 -341 -120 -475 -255 -132 -131 -209 -264 -254 -437 -69 -266 -6 -581 168 -847 57 -87 207 -239 285 -289 234 -150 455 -181 708 -101 143 45 313 167 422 304 289 360 343 826 137 1173 -53 89 -144 194 -227 263 -81 67 -239 147 -347 175 -102 28 -318 35 -417 14z m337 -277 c72 -15 202 -80 260 -130 159 -136 234 -287 243 -488 4 -90 1 -120 -22 -210 -68 -266 -258 -492 -476 -566 -93 -31 -218 -33 -311 -4 -218 67 -422 302 -489 565 -27 103 -29 263 -5 355 55 213 220 392 425 460 114 38 248 45 375 18z" />
                        <path d="M3207 2544 c-216 -46 -415 -214 -501 -421 -57 -138 -67 -303 -27 -433 70 -229 279 -536 558 -820 l123 -125 133 135 c275 281 462 550 543 785 24 68 28 96 28 190 1 135 -13 193 -71 313 -37 76 -60 108 -133 181 -72 73 -103 96 -181 134 -148 74 -313 95 -472 61z m299 -282 c43 -13 112 -58 157 -101 83 -79 127 -181 128 -294 1 -116 -40 -212 -172 -407 -78 -116 -243 -320 -258 -320 -10 0 -118 128 -197 235 -240 322 -285 488 -184 686 33 64 123 147 198 182 94 44 223 51 328 19z" />
                        <path d="M3340 1963 c-62 -20 -90 -56 -90 -119 0 -51 53 -104 104 -104 73 0 121 47 119 116 -1 45 -27 82 -68 100 -36 15 -41 15 -65 7z" />
                        <path d="M1825 2214 c-276 -14 -522 -44 -705 -88 -194 -46 -437 -130 -524 -181 -53 -31 -133 -114 -169 -176 -59 -101 -60 -108 -64 -556 l-4 -413 1170 0 1171 0 0 135 0 135 -1030 0 -1030 0 0 255 c0 280 2 295 59 352 74 73 418 181 726 227 200 29 363 38 643 35 l279 -4 7 129 c5 99 3 132 -6 138 -7 4 -52 8 -99 8 -47 0 -146 2 -220 4 -74 2 -165 2 -204 0z" />
                      </g>
                    </svg>
                    Location:
                  </strong>{" "}
                  {location}
                  {distance !== undefined ? (
                    distance < 10 ? (
                      <div>
                        {"("} less than 10 miles away{")"}
                      </div>
                    ) : distance < 50 ? (
                      <div>
                        {"("}less than 50 miles away{")"}
                      </div>
                    ) : distance < 100 ? (
                      <div>
                        {"("}less than 100 miles away{")"}
                      </div>
                    ) : distance < 250 ? (
                      <div>
                        {"("}less than 250 miles away{")"}
                      </div>
                    ) : (
                      <div></div>
                    )
                  ) : (
                    <div> </div>
                  )}
                </div>
                {!commitment && (
                  <Link
                    to={`/profile/${slug}`}
                    className="btn btn-custom-primary know-more-card-long mt-4"
                  >
                    Know More
                  </Link>
                )}
                <div className="card-text profile-card-desc">
                  {priorities && priorities.length > 0 && (
                    <>
                      <strong className="priority-head">Priorities:</strong>
                      <div className="mt-2">
                        <ul className="priority-list d-flex">
                          {priorities.map((priority, index) => (
                            <li key={index} className="priority-item col-md-2">
                              {priority.img ? (
                                <img
                                  src={priority.img}
                                  alt={`Priority ${index + 1}`}
                                  className="priority-image"
                                  loading="lazy" // Lazy loading for priority images
                                />
                              ) : (
                                <img
                                  className="priority-image"
                                  src={priorityimg}
                                  alt="Default Priority"
                                  loading="lazy"
                                />
                              )}
                              <div className="fw-semibold priority-text">
                                {priority.text}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </>
                  )}
                </div>
              </div>

              {/* {commitment ? (
                <div className={`bg-white commitment ${showAll ? "commitment-mobile" : ""}`}>
                  <h4 className="py-2 px-5 tagline-width">{commitment}</h4>
                </div>
              ) : (
                <Link
                  to={`/profile/${slug}`}
                  className="btn btn-custom-primary know-more-card-long mt-auto"
                >
                  Know More
                </Link>
              )} */}
            </div>
          </div>
          <div
            className={`col-md-4 d-flex align-self-end py-2 ${
              showAll ? "profile-photo-mobile" : ""
            }`}
          >
            <img
              src={image}
              className="img-fluid profile-photo rounded-start"
              alt={name}
              loading="lazy" // Lazy loading for profile image
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
