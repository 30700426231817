import React, { useState, useEffect } from 'react';

const LocationInput = ({ onLocationSelect }) => {
  const [citySuggestions, setCitySuggestions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [userLocation, setUserLocation] = useState({
    latitude: null,
    longitude: null,
    city: '',
    state: '',
    country: '',
  });

  useEffect(() => {
    const fetchUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
            fetchCityFromCoordinates(latitude, longitude);
          },
          (error) => {
            console.error('Error getting user location:', error);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    };

    const fetchCityFromCoordinates = async (lat, lng) => {
      const apiKey = process.env.REACT_APP_GEOCODE_API_KEY;
      const apiUrl = `https://api.opencagedata.com/geocode/v1/json?q=${lat}+${lng}&key=${apiKey}`;

      try {
        const response = await fetch(apiUrl);
        const data = await response.json();

        if (data.results && data.results.length > 0) {
          const { components, geometry } = data.results[0];
          console.log(components);
          const city = components.city || components.town || components.village;
          const state = components.state;
          const country = components.country;

          if (city) {
            setInputValue(`${city}, ${state}, ${country}`);
          } else {
            setInputValue(`${state}, ${country}`);
          }
          setUserLocation({
            latitude: geometry.lat,
            longitude: geometry.lng,
            city:
            components.city || components.town || components.village || "",
          state: components.state || "",
          country: components.country || "",
          });
          onLocationSelect({
            latitude: geometry.lat,
            longitude: geometry.lng,
            city: components.city || components.town || components.village || '',
            state: components.state || '',
            country: components.country || '',
          });
        }
      } catch (error) {
        console.error('Error fetching city from coordinates:', error);
      }
    };

    fetchUserLocation();

  }, []);

  const fetchCitySuggestions = async (query) => {
    const apiKey = process.env.REACT_APP_GEOCODE_API_KEY;
    const apiUrl = `https://api.opencagedata.com/geocode/v1/json?q=${query}&key=${apiKey}&limit=5`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      if (data.results) {
        const suggestions = data.results.map((result) => ({
          name: `${result.components.city || result.components.town || result.components.village || ''}, ${result.components.state || ''}, ${result.components.country || ''}`,
          lat: result.geometry.lat,
          lng: result.geometry.lng,
          city: result.components.city || result.components.town || result.components.village || '',
          state: result.components.state || '',
          country: result.components.country || '',
        }));
        setCitySuggestions(suggestions);
      } else {
        setCitySuggestions([]);
      }
    } catch (error) {
      console.error('Error fetching city suggestions:', error);
    }
  };

  const handleInputChange = (event) => {
    const searchText = event.target.value;
    setInputValue(searchText);
    if (searchText.length >= 3) {
      fetchCitySuggestions(searchText);
    } else {
      setCitySuggestions([]);
    }
  };

  const handleSelectLocation = (selected) => {
    const city = selected.city || selected.town || selected.village;
    const state = selected.state;
    const country = selected.country;

    if (city) {
      setInputValue(`${city}, ${state}, ${country}`);
    } else {
      setInputValue(`${state}, ${country}`);
    }    setUserLocation({
      latitude: selected.lat,
      longitude: selected.lng,
      city: selected.city,
      state: selected.state,
      country: selected.country,
    });
    setCitySuggestions([]);
    onLocationSelect({
      latitude: selected.lat,
      longitude: selected.lng,
      city: selected.city,
      state: selected.state,
      country: selected.country,
    });
  };

  return (
    <div className="form-group">
      {/* <label htmlFor="cityInput">City</label> */}
      <input
        type="text"
        className="form-control"
        id="cityInput"
        value={inputValue}
        placeholder="Enter city"
        onChange={handleInputChange}
      />
      {citySuggestions.length > 0 && (
        <ul className="list-group position-absolute z-3 mt-2">
          {citySuggestions.map((suggestion, index) => (
            <li
              key={index}
              className="list-group-item"
              onClick={() => handleSelectLocation(suggestion)}
              style={{ cursor: 'pointer' }}
            >
              {suggestion.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LocationInput;
