import React, { useState } from "react";
import Logo from "../../img/logo.webp";
import { Link } from "react-router-dom";
import "./Navbar.css";
import LocationInput from "../LocationInput/LocationInput";
import { Helmet } from 'react-helmet';
import { useMeta } from '../../MetaContext'; // Import the useMeta hook

function Navbar({handleLocationSelect}) {
  // const [location, setlocation] = useState([])
  // const handleLocationSelect = (loc) => {
  //   // console.log(loc);
  //   setlocation( loc );
  // };
  const { meta } = useMeta();

  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <Helmet>
          <description>{meta.description}</description>
          <meta name="description" content={meta.description}  />
        </Helmet>
        <div className="container-fluid">
          <Link className="navbar-brand ms-2 fw-semibold" to="/">
            <img src={Logo} style={{height:"70px"}}  alt="Lead4Earth" /> Lead For Earth
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-0 ms-auto mb-2 mb-lg-0">
              <li className="nav-item me-3">
                <Link to="/faq" className="nav-link active" aria-current="page" >
                  FAQs
                </Link>
              </li>
              <li className="nav-item me-3">
                <Link className="nav-link active" aria-current="page" to="/aboutus">
                  About us
                </Link>
              </li>
              <li className="nav-item me-3">
                <Link className="nav-link active" aria-current="page" to="/leader">
                  Leaders
                </Link>
              </li>
              <li className="nav-item me-3">
                <Link className="nav-link active" aria-current="page" to="/blog">
                  Blog
                </Link>
              </li>
              {/* <li className="nav-item me-3">
                <Link className="nav-link active" aria-current="page" to="/">
                  Support
                </Link>
              </li>
              <li className="nav-item me-3">
                <Link className="nav-link active" aria-current="page" to="/">
                  Endorsement
                </Link>
              </li> */}
              <div className="mt-1 mx-2">
                
              <LocationInput  onLocationSelect={handleLocationSelect}/>
              </div>
              <a href="https://www.paypal.com/donate/?hosted_button_id=ZZFFL8XAX8EZW" className="btn btn-sm btn-custom-primary py-auto pt-2 px-4 donate-btn me-2">DONATE</a>

            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
