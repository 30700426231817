import React, { Fragment, useState,useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import EndorsementForm from "./EndorsementForm";
import Person from "../../img/person.webp";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import nextArrowImg from "../../img/rightarrow.png";
import prevArrowImg from "../../img/leftarrow.png";
function EndorsementIframe() {
  const [showAllOrgs, setShowAllOrgs] = useState(false);
  const [showAllInds, setShowAllInds] = useState(false);
  const [viewAll, setviewAll] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [openEndorse, setopenEndorse] = useState(false);
  const { slug } = useParams();
  const [endorsements, setEndorsements] = useState([]);
  const [leader, setLeader] = useState({});

  const [error, setError] = useState(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <img src={nextArrowImg} alt="Next" />,
    prevArrow: <img src={prevArrowImg} alt="Prev" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    // setContentExpanded(false);

    const fetchLeaderData = async () => {
      try {
        // Fetch current leader data based on slug
        const url = `${process.env.REACT_APP_URLVAR}/api/leaders/leader/${slug}`;
        const response = await fetch(url);
        console.log(url);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        // console.log(response.data);
        const currentLeader = await response.json();
        setLeader(currentLeader);
       
       
      } catch (error) {
        console.log(error);
        setError(error.message);
      }
    };
    // console.log("hlw");
    fetchLeaderData();
  }, [slug ]);

  useEffect(() => {
    const fetchEndorsements = async () => {
      try {
        if (!leader._id) {
          return;
        }
        const endurl =  `${process.env.REACT_APP_URLVAR}/api/endorsements/${leader._id}`;
        console.log(endurl)
        // Fetch endorsements associated with the leader
        const response = await axios.get(
          endurl
        );
        console.log(response.data)
        setEndorsements(response.data || []);
      } catch (error) {
        console.error("Error fetching endorsements:", error);
      }
    };

    fetchEndorsements();
  }, [leader]);
  const handleShowMoreOrgs = () => {
    setShowAllOrgs(true);
  };
  const handleendorseopen = () => {
    setviewAll(!viewAll);
  };
  const handleShowMoreInds = () => {
    setShowAllInds(true);
  };

  const handleEndorseClick = () => {
    setShowForm((prev) => !prev); // Toggle form visibility
  };

  const organizationalEndorsements = Array.isArray(endorsements) && endorsements.filter(
    (endorsement) => endorsement.endorserType === "organization"
  );

  const individualEndorsements = Array.isArray(endorsements) &&endorsements.filter(
    (endorsement) => endorsement.endorserType === "individual"
  );

  const endorsementsToShowOrgs = showAllOrgs
    ? organizationalEndorsements
    :Array.isArray(organizationalEndorsements) && organizationalEndorsements.slice(0, 8);

  const endorsementsToShowInds = showAllInds
    ? individualEndorsements
    : Array.isArray(individualEndorsements) && individualEndorsements.slice(0, 8);

  return (
    <div className="container my-5">
      {endorsements.length >0 && <>
                <h2 className="text-center bg-white about-heading py-2 mb-2">
                  Endorsements
                </h2>
                <div className="endorsement-slider">
                  {endorsements.length === 1 ? (
                    <div className="endorsement d-flex justify-content-center align-items-center flex-column">
                      {endorsements[0].endorserType === "organization" ? (
                        <a
                          target="_blank"
                          href={
                            endorsements[0].organization.website.startsWith(
                              "http"
                            )
                              ? endorsements[0].organization.website
                              : "https://" +
                                endorsements[0].organization.website
                          }
                        >
                          <img
                            loading="lazy"
                            src={endorsements[0].organization.image}
                            alt={endorsements[0].organization.name}
                            className="endorsement-img mx-auto img-fluid"
                          />
                          <p className="endorsement-name text-center mt-3 fs-6 fw-semibold">
                            {endorsements[0].organization.name}
                          </p>
                        </a>
                      ) : (
                        <div className="individual-endorsement text-center">
                          <img
                            loading="lazy"
                            src={endorsements[0].individual.image}
                            alt={endorsements[0].individual.name}
                            className="endorsement-img mx-auto img-fluid"
                          />
                          <p className="endorsement-name mt-3 fs-6 fw-semibold">
                            {endorsements[0].individual.name}
                          </p>
                          <p className="endorsement-title">
                            {endorsements[0].individual.title}
                          </p>
                          <a
                            href={endorsements[0].individual.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            LinkedIn
                          </a>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Slider {...settings}>
                      {Array.isArray(endorsements) && endorsements.map((endorsement, index) => (
                        <div
                          key={index}
                          className="endorsement d-flex justify-content-center align-items-center flex-column"
                        >
                          {endorsement.endorserType === "organization" ? (
                            <a
                              target="_blank"
                              href={
                                endorsement.organization.website&&
                                endorsement.organization.website.startsWith(
                                  "http"
                                )
                                  ? endorsement.organization.website
                                  : "https://" +
                                    endorsement.organization.website
                              }
                            >
                              <img
                                loading="lazy"
                                src={endorsement.organization.image}
                                alt={endorsement.organization.name}
                                className="endorsement-img mx-auto img-fluid"
                              />
                              <p className="endorsement-name text-center mt-3 fs-6 fw-semibold">
                                {endorsement.organization.name}
                              </p>
                            </a>
                          ) : (
                            <div className="individual-endorsement text-center">
                              <img
                                loading="lazy"
                                src={endorsement.individual.image ? endorsement.individual.image : Person}
                                alt={endorsement.individual.name}
                                className="endorsement-img mx-auto img-fluid"
                              />
                              <p className="endorsement-name mt-3 fs-6 fw-semibold">
                                {endorsement.individual.name}
                              </p>
                              <p className="endorsement-title">
                                {endorsement.individual.title}
                              </p>
                              <a
                                href={endorsement.individual.linkedin}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                LinkedIn
                              </a>
                            </div>
                          )}
                        </div>
                      ))}
                    </Slider>
                  )}
                </div>
                
             <div className="d-flex justify-content-end">
                  <a className="text-primary text-decoration-underline view-more" onClick={handleendorseopen}>View all endorsements</a>
             </div>
                </>}
            
{viewAll&&<>
      {organizationalEndorsements.length>0&&<h3 className="text-center about-heading mt-5">Organizational Endorsements</h3>}
      <div className="row justify-content-center">
        {Array.isArray(endorsementsToShowOrgs)&&endorsementsToShowOrgs.map((endorsement, index) => (
          <div
            key={index}
            className=" col-12 col-sm-6 col-md-2 mb-4"
          >
            <div className="endorsement text-center">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                  endorsement.organization.website?.startsWith("http")
                    ? endorsement.organization.website
                    : "https://" + endorsement.organization.website
                }
              >
                <img
                  loading="lazy"
                  src={endorsement.organization.image}
                  alt={endorsement.organization.name}
                  className="endorsement-img img-fluid"
                />
                <p className="endorsement-name mt-3 fs-6 fw-semibold">
                  {endorsement.organization.name}
                </p>
              </a>
            </div>
          </div>
        ))}
      </div>

      {!showAllOrgs && organizationalEndorsements.length > 9 && (
        <div className="text-center mt-3">
          <button className="btn btn-primary" onClick={handleShowMoreOrgs}>
            Show More Organizations
          </button>
        </div>
      )}

      {individualEndorsements.length>0&&<h3 className="text-center about-heading mt-5">Individual Endorsements</h3>}
      <div className="row justify-content-center">
        {Array.isArray(endorsementsToShowInds)&&endorsementsToShowInds.map((endorsement, index) => (
          <div key={index} className="col-md-2 mb-4">
            <div className="endorsement text-center">
              <img
                loading="lazy"
                src={
                  endorsement.individual.image
                    ? endorsement.individual.image
                    : Person
                }
                alt={endorsement.individual.name}
                className="endorsement-img mx-auto img-fluid"
              />
              <p className="endorsement-name mt-3 fs-5 fw-semibold">
                {endorsement.individual.name} <br/> ({endorsement.individual.title})
              </p>
              {endorsement.comments && (
                <div>
                  Comment <br/> Why they support:<br/>
                  <Fragment>&nbsp;&nbsp;</Fragment>
                  {endorsement.comments}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {!showAllInds && individualEndorsements.length > 9 && (
        <div className="text-center mt-3">
          <button className="btn btn-primary" onClick={handleShowMoreInds}>
            Show More Individuals
          </button>
        </div>
      )}
</>}
      <div className="d-flex justify-content-center mt-3">
        {leader?.name && (
          <button
            onClick={handleEndorseClick}
            className="btn border fw-bold fs-5 mt-3 shadow"
          >
            Endorse {leader.name.split(" ")[0]}
          </button>
        )}
      </div>

      {showForm && (
        <div className="mt-4">
          <EndorsementForm leader={leader._id} />
        </div>
      )}
    </div>
  );
}

export default EndorsementIframe;
