import React, { Fragment, useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ProfileCard from "../Home/ProfileCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Profilepage.css";
import nextArrowImg from "../../img/rightarrow.png";
import prevArrowImg from "../../img/leftarrow.png";
import { ReadOnlyCKEditor } from "../CKEditor/CKEditor5Editor";
import { MapContainer, TileLayer, Circle, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import EndorsementForm from "./EndorsementForm";
import axios from "axios";
import InstagramFeeds from "./InstagramFeeds";
import { useMeta } from "../../MetaContext"; // Import the useMeta hook
import Person from "../../img/person.webp"
import { HelmetProvider, Helmet } from "react-helmet-async";
import EndorsementGrid from "./Endorsementgrid";
import ImageGallery from "./ImageGallery";
import EventList from "./EventList";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const getYoutubeVideoId = (url) => {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const matches = url.match(regex);
  return matches ? matches[1] : null;
};

const generateSlug = (title) => {
  if (title === undefined) return "";
  return title.toLowerCase().replace(/ /g, "-");
};

function Profilepage() {
  const navigate = useNavigate();
  const { slug } = useParams();
  const { setMeta } = useMeta();

  const [openEndorse, setopenEndorse] = useState(false);
  const topRef = useRef(null);
  const scrollTotop = () => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const [leader, setLeader] = useState({});
  const [endorsements, setEndorsements] = useState([]);
  const [IndividualEndorsements, setIndividualEndorsements] = useState([]);
  const [error, setError] = useState(null);
  const [isContentExpanded, setContentExpanded] = useState(false);
  const [nextLeader, setNextLeader] = useState(null);
  const [prevLeader, setPrevLeader] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("tab1");
  const [fieldsDisabled, setFieldsDisabled] = useState(false);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <img src={nextArrowImg} alt="Next" />,
    prevArrow: <img src={prevArrowImg} alt="Prev" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getContentPreview = (content) => {
    const words = content.split(/\s+/);
    if (words.length > 255 && !isContentExpanded) {
      return words.slice(0, 225).join(" ") + "...";
    }
    return content;
  };

  useEffect(() => {
    setContentExpanded(false);

    const fetchLeaderData = async () => {
      try {
        // Fetch current leader data based on slug
        const url = `${process.env.REACT_APP_URLVAR}/api/leaders/leader/${slug}`;
        const response = await fetch(url);
        console.log(url);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        // console.log(response.data);
        const currentLeader = await response.json();
        setLeader(currentLeader);
        setIsLoading(false);
        // Fetch next and previous leader data
        const navResponse = await fetch(
          `${process.env.REACT_APP_URLVAR}/api/leaders/leader/nav/${slug}`
        );
        const navData = await navResponse.json();
        setPrevLeader(navData.prevLeader);
        setNextLeader(navData.nextLeader);

        // Update meta information
        const meta = {
          title: `${currentLeader.title}`,
          description: `${
            currentLeader.tagline == null
              ? "Lead4earth:" + currentLeader.name
              : currentLeader.tagline
          }`,
          url: window.location.href,
          image: currentLeader.image,
        };
        setMeta(meta);
      } catch (error) {
        console.log(error);
        setError(error.message);
      }
    };
    console.log("hlw");
    fetchLeaderData();
  }, [slug, setMeta]);

  useEffect(() => {
    const fetchEndorsements = async () => {
      try {
        if (!leader._id) {
          return;
        }
        const endurl =  `${process.env.REACT_APP_URLVAR}/api/endorsements/${leader._id}`;
        console.log(endurl)
        // Fetch endorsements associated with the leader
        const response = await axios.get(
          endurl
        );
        console.log(response.data)
        setEndorsements(response.data || []);
      } catch (error) {
        console.error("Error fetching endorsements:", error);
      }
    };

    fetchEndorsements();
  }, [leader]);

  const handleNext = () => {
    navigate(`/profile/${generateSlug(nextLeader.title)}`);
    scrollTotop();
  };

  const handlePrev = () => {
    navigate(`/profile/${generateSlug(prevLeader.title)}`);
    scrollTotop();
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleendorseopen = () => {
    setopenEndorse(!openEndorse);
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>{leader.title}</title>
        <meta
          property="og:description"
          content={
            leader.tagline == null
              ? "Lead4earth:" + leader.name
              : leader.tagline
          }
        />
        <meta
          property="description"
          content={
            leader.tagline == null
              ? "Lead4earth:" + leader.name
              : leader.tagline
          }
        />
        <link property="icon" href={leader.image} />
        <meta property="og:image" content={leader.image} />
        <meta property="image" content={leader.image} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div ref={topRef}>
        <ProfileCard
          id={leader._id}
          name={leader.name}
          title={leader.title}
          location={leader.location}
          priorities={leader.priorities}
          image={leader.image}
          commitment={leader.tagline}
        />
        <EventList leaderId={leader._id}/>
        <div className="d-flex  justify-content-between mt-3  ">
          <button onClick={handlePrev} className="nextprevbutton ms-5 btn-left-bg d-flex  ">
            {prevLeader && (
              <div className="d-flex name-width my-auto ms-3">
                <img
                  loading="lazy"
                  src={prevLeader.image}
                  alt={prevLeader.name}
                  className="img-fluid rounded-circle"
                  style={{ width: "50px", height: "50px" }}
                />
                <div className="my-auto mx-auto ms-2">{prevLeader.name}</div>
              </div>
            )}
          </button>
          <ul className="nav mt-4 custom-nav-tabs justify-content-center">
            <li className="nav-item">
              <a
                className={`custom-nav-link ${
                  activeTab === "tab1" ? "active" : ""
                }`}
                onClick={() => handleTabClick("tab1")}
                style={{ cursor: "pointer" }}
              >
                Home
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`custom-nav-link ${
                  activeTab === "tab2" ? "active" : ""
                }`}
                onClick={() => handleTabClick("tab2")}
                style={{ cursor: "pointer" }}
              >
                Endorsements
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`custom-nav-link ${
                  activeTab === "tab3" ? "active" : ""
                }`}
                onClick={() => handleTabClick("tab3")}
                style={{ cursor: "pointer" }}
              >
                Gallery
              </a>
            </li>
          </ul>
          <button onClick={handleNext} className="nextprevbutton me-5 btn-right-bg d-flex ">
            {nextLeader && (
              <div className="d-flex name-width my-auto ms-auto me-3">
                <div className="my-auto mx-auto">{nextLeader.name}</div>
                <img
                  loading="lazy"
                  src={nextLeader.image}
                  alt={nextLeader.name}
                  className="img-fluid rounded-circle"
                  style={{ width: "50px", height: "50px" }}
                />
              </div>
            )}
          </button>
        </div>
        <div className=" " style={{ marginTop: "50px" }}>
          {/* Tabs Header */}
          {/* <ul className="nav custom-nav-tabs justify-content-center">
            <li className="nav-item">
              <a
                className={`custom-nav-link ${
                  activeTab === "tab1" ? "active" : ""
                }`}
                onClick={() => handleTabClick("tab1")}
                style={{ cursor: "pointer" }}
              >
                Home
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`custom-nav-link ${
                  activeTab === "tab2" ? "active" : ""
                }`}
                onClick={() => handleTabClick("tab2")}
                style={{ cursor: "pointer" }}
              >
                Endorsements
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`custom-nav-link ${
                  activeTab === "tab3" ? "active" : ""
                }`}
                onClick={() => handleTabClick("tab3")}
                style={{ cursor: "pointer" }}
              >
                Gallery
              </a>
            </li>
          </ul> */}

          {/* Tabs Content */}
          <div className="tab-content mt-3">
            {/* Tab 1 */}

            <div
              className={`tab-pane fade ${
                activeTab === "tab1" ? "show active" : ""
              }`}
            >
              <div className="container mt-5">
                <div className="row ">
                  <div className="map-container col-md-4 mt-3">
                    <h1 className="text-center bg-white about-heading py-2">
                      Impact
                    </h1>
                    {leader.mapImage == null && leader.radius && (
                      <MapContainer
                        center={[
                          parseFloat(leader.lat),
                          parseFloat(leader.lng),
                        ]}
                        zoom={7}
                        style={{ height: "350px", width: "100%" }}
                      >
                        <TileLayer
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                        <Marker
                          position={[
                            parseFloat(leader.lat),
                            parseFloat(leader.lng),
                          ]}
                        >
                          <Popup>{leader.name}</Popup>
                        </Marker>
                        <Circle
                          center={[
                            parseFloat(leader.lat),
                            parseFloat(leader.lng),
                          ]}
                          radius={leader.radius * 1609.34} // Convert miles to meters
                        />
                      </MapContainer>
                    )}
                    {leader.mapImage && (
                      <img
                        loading="lazy"
                        src={leader.mapImage}
                        style={{ height: "350px", width: "100%" }}
                      />
                    )}
                    {leader.constituency && (
                      <h3 className="text-center mt-4">
                        {leader.constituency}
                      </h3>
                    )}

                    {leader.youtubeVideo && (
                      <div className="video-container mt-3">
                        <iframe
                          style={{ width: "100%", height: "300px" }}
                          src={`https://www.youtube.com/embed/${getYoutubeVideoId(
                            leader.youtubeVideo
                          )}`}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    )}
                  </div>

                  <div className="flex-grow-1 col-md-8">
                    <h1 className="text-center bg-white about-heading py-2">
                      About {leader.name}
                    </h1>
                    <div
                      className="text-center  text-editor  pb-3"
                      style={{ fontSize: "17px" }}
                    >
                      {leader && leader.aboutThem ? (
                        <div>
                          <ReadOnlyCKEditor
                            value={getContentPreview(leader.aboutThem)}
                          />
                          {leader.aboutThem &&
                            leader.aboutThem.split(/\s+/).length > 300 && (
                              <button
                                onClick={() =>
                                  setContentExpanded(!isContentExpanded)
                                }
                                className="btn btn-link"
                              >
                                {isContentExpanded ? "Read Less" : "Read More"}
                              </button>
                            )}
                        </div>
                      ) : (
                        <p>Loading...</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center  mt-5 ">
                <div className="row w-100 mx-auto justify-content-center">
                  {leader.donationLink && (
                    <a
                      href={leader.donationLink}
                      className="btn btn-custom-primary  col-md-2 mt-3 mx-1"
                    >
                      Donate for {leader.name}
                    </a>
                  )}
                  <a
                    href="https://www.paypal.com/donate/?hosted_button_id=ZZFFL8XAX8EZW"
                    className="btn btn-custom-primary mx-1 mt-3 col-md-2"
                  >
                    Donate to Support Our Team
                  </a>
                  <a
                    target="_blank"
                    href={
                      leader.website.startsWith("http")
                        ? leader.website
                        : `https://${leader.website}`
                    }
                    className="btn btn-custom-primary mx-1 col-md-2 mt-3"
                  >
                    Visit More Info
                  </a>
                </div>
                <br />
                <br />
                {leader.socials &&
                  leader.socials.map((social, index) => (
                    <a
                      key={index}
                      href={social.link}
                      className="mx-3 fs-4 "
                      aria-label={`${social.platform} link for ${leader.name}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className={`bi bi-${social.platform}`}></i>
                    </a>
                  ))}
              </div>
              <div className="d-flex nextprevbutton-mobile justify-content-between mt-3 container ">
                <button onClick={handlePrev} className=" btn-left-bg d-flex  ">
                  {prevLeader && (
                    <div className="d-flex name-width my-auto ms-3">
                      <img
                        loading="lazy"
                        src={prevLeader.image}
                        alt={prevLeader.name}
                        className="img-fluid rounded-circle"
                        style={{ width: "50px", height: "50px" }}
                      />
                      <div className="my-auto mx-auto ms-2">
                        {prevLeader.name}
                      </div>
                    </div>
                  )}
                </button>
                <button onClick={handleNext} className=" btn-right-bg d-flex ">
                  {nextLeader && (
                    <div className="d-flex name-width my-auto ms-auto me-3">
                      <div className="my-auto mx-auto">{nextLeader.name}</div>
                      <img
                        loading="lazy"
                        src={nextLeader.image}
                        alt={nextLeader.name}
                        className="img-fluid rounded-circle"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </div>
                  )}
                </button>
              </div>
              <div className="container my-5">
                <h1 className="text-center bg-white about-heading py-2 mb-2">
                  Endorsements
                </h1>
                <div className="endorsement-slider">
                  {endorsements.length === 1 ? (
                    <div className="endorsement d-flex justify-content-center align-items-center flex-column">
                      {endorsements[0].endorserType === "organization" ? (
                        <a
                          target="_blank"
                          href={
                            endorsements[0].organization.website.startsWith(
                              "http"
                            )
                              ? endorsements[0].organization.website
                              : "https://" +
                                endorsements[0].organization.website
                          }
                        >
                          <img
                            loading="lazy"
                            src={endorsements[0].organization.image}
                            alt={endorsements[0].organization.name}
                            className="endorsement-img mx-auto img-fluid"
                          />
                          <p className="endorsement-name text-center mt-3 fs-6 fw-semibold">
                            {endorsements[0].organization.name}
                          </p>
                        </a>
                      ) : (
                        <div className="individual-endorsement text-center">
                          <img
                            loading="lazy"
                            src={endorsements[0].individual.image?endorsements[0].individual.image : Person}
                            alt={endorsements[0].individual.name}
                            className="endorsement-img mx-auto img-fluid"
                          />
                          <p className="endorsement-name mt-3 fs-6 fw-semibold">
                            {endorsements[0].individual.name}
                          </p>
                          <p className="endorsement-title">
                            {endorsements[0].individual.title}
                          </p>
                          {endorsements[0].individual.linkedin !== "null" &&<a
                            href={endorsements[0].individual.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            LinkedIn {endorsements[0].individual.linkedin}
                          </a>}
                        </div>
                      )}
                    </div>
                  ) : (
                    <Slider {...settings}>
                      {Array.isArray(endorsements) && endorsements.map((endorsement, index) => (
                        <div
                          key={index}
                          className="endorsement d-flex justify-content-center align-items-center flex-column"
                        >
                          {endorsement.endorserType === "organization" ? (
                            <a
                              target="_blank"
                              href={
                                endorsement.organization.website&&
                                endorsement.organization.website.startsWith(
                                  "http"
                                )
                                  ? endorsement.organization.website
                                  : "https://" +
                                    endorsement.organization.website
                              }
                            >
                              <img
                                loading="lazy"
                                src={endorsement.organization.image}
                                alt={endorsement.organization.name}
                                className="endorsement-img mx-auto img-fluid"
                              />
                              <p className="endorsement-name text-center mt-3 fs-6 fw-semibold">
                                {endorsement.organization.name}
                              </p>
                            </a>
                          ) : (
                            <div className="individual-endorsement text-center">
                              <img
                                loading="lazy"
                                src={endorsement.individual.image ? endorsement.individual.image : Person}
                                alt={endorsement.individual.name}
                                className="endorsement-img mx-auto img-fluid"
                              />
                              <p className="endorsement-name mt-3 fs-6 fw-semibold">
                                {endorsement.individual.name}
                              </p>
                              <p className="endorsement-title">
                                {endorsement.individual.title}
                              </p>
                              {endorsements[0].individual && endorsements[0].individual.linkedin !=="null" &&<a
                            href={endorsements[0].individual.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            LinkedIn
                          </a>}
                            </div>
                          )}
                        </div>
                      ))}
                    </Slider>
                  )}
                </div>
                <div className="d-flex justify-content-center mt-3">
                  {leader.name && (
                    <a
                      onClick={handleendorseopen}
                      className="btn border fw-bloder fs-5 mt-3 shadow"
                    >
                      Endorse {leader.name.split(" ")[0]}
                    </a>
                  )}
                  {/* <a
                      data-bs-toggle="modal"
                      data-bs-target="#endorsements"
                      className="btn border fw-bloder fs-5 mt-3 shadow"
                    >
                      View All Endorsement
                    </a> */}
                </div>
              </div>

              {openEndorse && (
                <div>
                  <EndorsementForm ID={leader._id} />
                </div>
              )}
              <div className="container mt-5">
                <h1>{leader.name}</h1>
                <p>{leader.location}</p>
                <InstagramFeeds
                  leaderName={leader.name}
                  leaderLocation={leader.location}
                  hashtag={"#Lead4Earth"}
                  noofpost= {6}
                />
              </div>
            </div>

            {/* Tab 2 */}
            <div
              className={`tab-pane fade ${
                activeTab === "tab2" ? "show active" : ""
              }`}
            >
              <EndorsementGrid
                endorsements={endorsements}
                individual={IndividualEndorsements}
                leader={leader}
              />
            </div>

            {/* Tab 3 */}
            <div
              className={`tab-pane fade ${
                activeTab === "tab3" ? "show active" : ""
              }`}
            >
              <div className="container">
                <ImageGallery images={leader.images} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
}

export default Profilepage;
