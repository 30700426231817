import React, { useState } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";

const ImageGallery = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleShowModal = (image) => {
    setSelectedImage(image);
    const modal = new window.bootstrap.Modal(document.getElementById('imageModal'));
    modal.show();
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        {images && images.map((image, index) => (
          <div key={index} className="col-md-3 mx-3  card mb-4">
            <img
              src={image}
              alt={`Gallery Image ${index + 1}`}
              className="img-fluid  leadergalleryimg"
              onClick={() => handleShowModal(image)}
              style={{ cursor: "pointer" ,aspectRatio : "1/1" }}
            />
          </div>
        ))}
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="imageModal"
        tabIndex="-1"
        aria-labelledby="imageModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-img modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body d-flex justify-content-center">
              {selectedImage && (
                <img
                  src={selectedImage}
                  alt="Selected"
                  className="img-fluid"
                  style={{ width: "80vh" ,aspectRatio:"13/12" ,objectFit : "contain" }}
                />
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageGallery;
