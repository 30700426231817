// CKEditor5Editor.js
import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const CKEditor5Editor = ({ value, onChange }) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      config={{
        removePlugins: ['ImageUpload','blockQuote','insertTable', 'MediaEmbed', 'EasyImage', 'ImageToolbar', 'ImageCaption', 'ImageStyle', 'ImageResize'],
        toolbar: [
          'heading', '|',
          'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|',
            'undo', 'redo'
        ]
      }}
      data={value}
      onChange={(event, editor) => {
        const data = editor.getData();
        onChange(event ,editor);
      }}
    />
  );
};
const ReadOnlyCKEditor = ({ value }) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      data={value}
      disabled = {true}
      config={{
        toolbar: [],
        isReadOnly: true,
      }}
    />
  );
};
export  {CKEditor5Editor,ReadOnlyCKEditor};
