// src/components/UploadForm.js
import React, { useState } from 'react';
import axios from 'axios';

const EventPhotoVideo = () => {
  const [files, setFiles] = useState([]);
  const [caption, setCaption] = useState('');
  const [loading, setLoading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const handleFilesChange = (e) => {
    setFiles(e.target.files);
  };

  const handleCaptionChange = (e) => {
    setCaption(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setUploadSuccess(false);

    const formData = new FormData();
    Array.from(files).forEach((file) => formData.append('files', file));
    formData.append('caption', caption);

    try {
      const response = await axios.post(`${process.env.REACT_APP_URLVAR}/api/event/uploadeventfile`, formData, {
        headers: { 'Content-Type': 'multipart/form-data','x-auth-token': localStorage.getItem("Token") },
        

      });

      setUploadSuccess(true);
      alert('Files uploaded successfully');
      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error uploading files:', error);
      alert('Error uploading files. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Upload Images/Videos with Caption</h2>
      <form onSubmit={handleSubmit} className="border p-4 rounded shadow-sm bg-light">
        <div className="form-group">
          <label htmlFor="fileInput">Choose Files</label>
          <input
            type="file"
            id="fileInput"
            className="form-control"
            multiple
            onChange={handleFilesChange}
            accept="image/*,video/*"
            required
          />
          <small className="form-text text-muted">
            You can upload multiple images and videos.
          </small>
        </div>
        <div className="form-group">
          <label htmlFor="captionInput">Caption</label>
          <input
            type="text"
            id="captionInput"
            className="form-control"
            value={caption}
            onChange={handleCaptionChange}
            placeholder="Enter caption"
            required
          />
        </div>
        <button
          type="submit"
          className="btn btn-custom-primary mx-auto btn-block mt-3"
          disabled={loading}
        >
          {loading ? 'Uploading...' : 'Upload'}
        </button>
        {uploadSuccess && (
          <div className="alert alert-success mt-3" role="alert">
            Files uploaded successfully!
          </div>
        )}
      </form>
    </div>
  );
};

export default EventPhotoVideo;
