import React, { createContext, useContext, useState } from 'react';
import { Helmet } from 'react-helmet';

// Create a context with a default value
const MetaContext = createContext();

// Create a custom hook to use the MetaContext
export const useMeta = () => useContext(MetaContext);

// Create a provider component
export const MetaProvider = ({ children }) => {
  const [meta, setMeta] = useState({
    title: 'Lead4Earth',
    description: 'Connecting volunteers with environmental leaders',
    url: window.location.href,
    image: 'https://lead4earth.org/static/media/logo.1f38bb897b0a49bcd876.png',
  });

  return (
    <MetaContext.Provider value={{ meta, setMeta }}>

      <Helmet>
        <title>{meta.title}</title>

      </Helmet>
      {children}
    </MetaContext.Provider>
  );
};

