import React from "react"

function SignupPage() {
  return (
    <div>
      hi
    </div>
  )
};

export default SignupPage;
