import React, { useEffect, useState } from "react";
import axios from "axios";

const InstagramFeeds = ({ leaderName, leaderLocation, hashtag ,noofpost }) => {
  const [feeds, setFeeds] = useState([]);
  const [visibleCount, setVisibleCount] = useState(noofpost);

  useEffect(() => {
    const fetchFeeds = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URLVAR}/api/instagram/feeds?leaderName=${leaderName}&leaderLocation=${leaderLocation}&hashtag=${hashtag}`
        );
        setFeeds(response.data);
      } catch (error) {
        console.error("Error fetching Instagram feeds:", error);
      }
    };

    fetchFeeds();
  }, [leaderName, leaderLocation, hashtag]);

  const loadMorePosts = () => {
    setVisibleCount(prevCount => prevCount + 6); // Load 10 more posts each time
  };

  return (
    <div className='feeds-container row container'>
      {Array.isArray(feeds) && feeds.slice(0, visibleCount).map(feed => (
        <div className="post col-md-4" key={feed.id}>
          <a href={feed.permalink} target="_blank" rel="noopener noreferrer">
            {feed.media_type === 'VIDEO' ? (
              <video controls onClick={(e) => e.target.play()} style={{  width: '100%', objectFit: 'cover' }}>
                <source src={feed.media_url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img loading="lazy" src={feed.media_url} alt={feed.caption} style={{  width: '100%', objectFit: 'cover' }} />
            )}
          </a>
          <Caption text={feed.caption} />
        </div>
      ))}
      {visibleCount < feeds.length && (
        <div className="col-md-12 text-center">
          <button onClick={loadMorePosts} className="btn btn-custom-primary mt-3">
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

function Caption({ text }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleReadMore = () => setIsExpanded(!isExpanded);

  return (
    <p>
      {isExpanded ? text : `${text.substring(0, 100)}... `}
      {text.length > 100 && (
        <span onClick={toggleReadMore} style={{ color: 'blue', cursor: 'pointer' }}>
          {isExpanded ? 'Read less' : 'Read more'}
        </span>
      )}
    </p>
  );
}

export default InstagramFeeds;
