import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
const RSVPPage = () => {
  const { eventId } = useParams(); // Get the eventId from the URL
  const [event, setEvent] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    numberOfGuests: 1,
  });
  const [isSubmitted, setIsSubmitted] = useState(false); // Track form submission

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_URLVAR}/api/event/${eventId}`);
        setEvent(response.data.event); // Fetch event details
      } catch (error) {
        console.error('Error fetching event details:', error);
      }
    };
    fetchEvent();
  }, [eventId]);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleRSVPSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${process.env.REACT_APP_URLVAR}/api/event/rsvp/`, {
        eventId,
        ...formData,
      });

      // After submission, show the thank you message
      setIsSubmitted(true);
    } catch (error) {
      console.error('Error RSVPing:', error);
      alert('Error RSVPing. Please try again.');
    }
  };

  return (
    <div className="container mt-5">
      {event && !isSubmitted && (
        <>
        <Helmet>
            <title>{event.title} - RSVP</title>
            <meta property="og:title" content={event.title} />
            <meta property="og:description" content={event.shortDescription} />
            <meta property="og:image" content={event.img} />
            <meta property="og:url" content={`${window.location.href}`} />
            <meta property="og:type" content="website" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={event.title} />
            <meta name="twitter:description" content={event.shortDescription} />
            <meta name="twitter:image" content={event.img} />
            <link href={event.img} rel="icon"></link>
          </Helmet>

          <h2>RSVP for {event.title}</h2>
          <p>{event.shortDescription}</p>
          <div className="poster mb-3 text-center">
            <img src={event.img} alt="Event Poster" className="img-fluid event-poster " />
          </div>

          <form className='col-md-8 mx-auto border border-1 p-3 rounded' onSubmit={handleRSVPSubmit}>
            <div className="mb-3">
              <label>Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="form-control"
                required
              />
            </div>
            <div className="mb-3">
              <label>Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="form-control"
                required
              />
            </div>
            <div className="mb-3">
              <label>Phone Number:</label>
              <input
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                className="form-control"
                required
              />
            </div>
            <div className="mb-3">
              <label>Number of Guests:</label>
              <input
                type="number"
                name="numberOfGuests"
                value={formData.numberOfGuests}
                min="1"
                onChange={handleInputChange}
                className="form-control"
                required
              />
            </div>
            <button type="submit" className="btn btn-custom-primary">Submit RSVP</button>
          </form>
        </>
      )}

      {/* Show thank you message after form submission */}
      {isSubmitted && (
        <div className="thank-you-message text-center">
          <h2>Thank You for Your RSVP!</h2>
          <p>We look forward to seeing you at the event.</p>
        </div>
      )}
    </div>
  );
};

export default RSVPPage;
