import React, { Fragment, useState } from "react";
import EndorsementForm from "./EndorsementForm";
import Person from "../../img/person.webp"

function EndorsementGrid({ endorsements, leader }) {
  const [showAllOrgs, setShowAllOrgs] = useState(false);
  const [showAllInds, setShowAllInds] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const handleShowMoreOrgs = () => {
    setShowAllOrgs(true);
  };

  const handleShowMoreInds = () => {
    setShowAllInds(true);
  };

  const handleEndorseClick = () => {
    setShowForm((prev) => !prev); // Toggle form visibility
  };

  const organizationalEndorsements = Array.isArray(endorsements) && endorsements.filter(
    (endorsement) => endorsement.endorserType === "organization"
  );

  const individualEndorsements = Array.isArray(endorsements) &&endorsements.filter(
    (endorsement) => endorsement.endorserType === "individual"
  );

  const endorsementsToShowOrgs = showAllOrgs
    ? organizationalEndorsements
    :Array.isArray(organizationalEndorsements) && organizationalEndorsements.slice(0, 8);

  const endorsementsToShowInds = showAllInds
    ? individualEndorsements
    : Array.isArray(individualEndorsements) && individualEndorsements.slice(0, 8);

  return (
    <div className="container my-5">
      <h1 className="text-center bg-white about-heading py-2 mb-2">
        Endorsements
      </h1>

      <h3 className="text-center">Organizational Endorsements</h3>
      <div className="row justify-content-center">
        {Array.isArray(endorsementsToShowOrgs)&&endorsementsToShowOrgs.map((endorsement, index) => (
          <div
            key={index}
            className=" col-12 col-sm-6 col-md-2 mb-4"
          >
            <div className="endorsement text-center">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                  endorsement.organization.website?.startsWith("http")
                    ? endorsement.organization.website
                    : "https://" + endorsement.organization.website
                }
              >
                <img
                  loading="lazy"
                  src={endorsement.organization.image}
                  alt={endorsement.organization.name}
                  className="endorsement-img img-fluid"
                />
                <p className="endorsement-name mt-3 fs-6 fw-semibold">
                  {endorsement.organization.name}
                </p>
              </a>
            </div>
          </div>
        ))}
      </div>

      {!showAllOrgs && organizationalEndorsements.length > 9 && (
        <div className="text-center mt-3">
          <button className="btn btn-primary" onClick={handleShowMoreOrgs}>
            Show More Organizations
          </button>
        </div>
      )}

      <h3 className="text-center mt-5">Individual Endorsements</h3>
      <div className="row justify-content-center">
        {Array.isArray(endorsementsToShowInds)&&endorsementsToShowInds.map((endorsement, index) => (
          <div key={index} className="col-md-2 mb-4">
            <div className="endorsement text-center">
              <img
                loading="lazy"
                src={
                  endorsement.individual.image
                    ? endorsement.individual.image
                    : Person
                }
                alt={endorsement.individual.name}
                className="endorsement-img mx-auto img-fluid"
              />
              <p className="endorsement-name mt-3 fs-5 fw-semibold">
                {endorsement.individual.name} <br/> ({endorsement.individual.title})
              </p>
              {endorsement.comments && (
                <div>
                  Comment <br/> Why they support:<br/>
                  <Fragment>&nbsp;&nbsp;</Fragment>
                  {endorsement.comments}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {!showAllInds && individualEndorsements.length > 9 && (
        <div className="text-center mt-3">
          <button className="btn btn-primary" onClick={handleShowMoreInds}>
            Show More Individuals
          </button>
        </div>
      )}

      <div className="d-flex justify-content-center mt-3">
        {leader?.name && (
          <button
            onClick={handleEndorseClick}
            className="btn border fw-bold fs-5 mt-3 shadow"
          >
            Endorse {leader.name.split(" ")[0]}
          </button>
        )}
      </div>

      {showForm && (
        <div className="mt-4">
          <EndorsementForm leader={leader._id} />
        </div>
      )}
    </div>
  );
}

export default EndorsementGrid;
