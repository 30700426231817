import React, { useState, useEffect } from "react";
import axios from "axios";

const ConnectWithUsResponses = () => {
  const [responses, setResponses] = useState([]);

  useEffect(() => {
    const fetchResponses = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URLVAR}/api/general/connectWithUs`,
          {
            headers: {
              "x-auth-token": localStorage.getItem('Token'),
            },
          }
        ); // Adjust URL as per your backend route
        setResponses(response.data);
      } catch (error) {
        console.error("Error fetching responses:", error);
      }
    };

    fetchResponses();
  }, []);

  return (
    <div>
      <h2 className="text-center mt-5">Connect With Us Responses</h2>
      <div className="row px-5 w-100 my-3">
        {responses.map((response) => (
          <div className=" border p-2 rounded shadow m-2 col-md-3" key={response._id}>
            <strong>Name:</strong> {response.name}
            <br /> <br/>
            <strong>Email:</strong> {response.email}
            <br /> <br/>
            <strong>Mobile:</strong> {response.mobile}
            <br /> <br/>
            <strong>Location:</strong> {response.location}
            <br /> <br/>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConnectWithUsResponses;
